<template>
    <div class="">
        <div class="row">
            <div class="col-12">
                <div class="form-group mb-3">
                    <label for="inputName">
                         Name
                        <span class="text-danger small">*</span>
                    </label>
                    <input type="text" v-validate="{ required: true}" v-model="data.name"
                      id="inputName" name="name" class="form-control" :class="{ 'is-invalid': errors.has('name') }"
                      placeholder="Task name" />
                    <span class="text-danger small"  v-show="errors.has('name')">{{ errors.first('name') }}</span>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group mb-3">
                    <label for="slug">
                        Slug
                        <span class="text-danger small">*</span>
                    </label>
                    <input type="text" v-model="data.slug" v-validate="{ required: true, regex: /^[a-z0-9-_]+$/}"
                     id="slug" class="form-control" name="slug" :class="{ 'is-invalid': errors.has('slug')}" placeholder="slug" />
                    <span class="text-danger small" v-show="errors.has('slug')">{{ errors.first('slug') }}</span>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group mb-3">
                    <label class="">Task Type</label>
                    <select class="custom-select" v-validate="{required: true}" v-model="data.type"
                     name="type" placeholder="--select type--" :class="{ 'is-invalid': errors.has('type')}">
                        <option disabled value="">--select type--</option>
                        <option value="digital">Digital</option>
                        <option value="printable">Printable</option>
                    </select>
                    <span class="text-danger small"  v-show="errors.has('type')">{{ errors.first('type') }}</span>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group mb-3">
                    <label class="">Delivery Time</label>
                    <select class="custom-select" v-validate="{required: true}" v-model="data.delivery_time"
                     name="delivery_time" placeholder="--select delivery time--" :class="{ 'is-invalid': errors.has('delivery_time')}">
                        <option disabled value="">--select delivery time--</option>
                        <option v-for="dT in 20" :value="dT*24" :key="dT">{{dT}} days</option>
                    </select>
                    <span class="text-danger small"  v-show="errors.has('delivery_time')">{{ errors.first('delivery_time') }}</span>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group mb-3">
                    <label>Plan</label>
                    <multiselect :options="plans"
                    searchable track-by="id"
                    :custom-label="customPlanLabel"
                    :multiple="true" :close-on-select="false"
                    v-model="data.plans" placeholder="--select plan--"></multiselect>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group mb-3">
                    <b-form-checkbox v-model="data.is_active" name="is_active" switch>
                        Visibility
                    </b-form-checkbox>
                </div>
            </div>
            <div class="col-lg-12 mb-3">
                <vue-dropzone id="reqTaskUploadDrop" ref="reqTaskUploadDrop"
                :use-custom-slot="true" :maxFiles="1"
                    acceptedFiles="image/*,.webp">
                    <div class="my-0 needsclick">
                        <i class="h1 text-muted ri-upload-cloud-2-line"></i>
                        <h4 class="my-1"> {{editMode? 'Add to update image (photo gallery)':'Drop file or click to add'}}</h4>
                        <span class="text-muted font-13">
                            (Dimension should be 500 x 500 Max: 23kb)
                        </span>
                    </div>
                </vue-dropzone>
            </div>
            <div class="col-12">
                <div class="form-group mb-3">
                    <label class="form-label" for="description">Description</label>
                    <textarea id="description" v-validate="{ required: true, max: 200}"
                        v-model="data.description" :class="{ 'is-invalid': errors.has('description')}" class="form-control" rows="3"
                        placeholder="Write description" name="description"></textarea>
                    <span class="text-danger small"  v-show="errors.has('description')">{{ errors.first('description') }}</span>
                </div>
            </div>
            <div class="col-12">
                <div class="d-flex mt-3 justify-content-end">
                    <b-button variant="primary" 
                    @click.prevent="editMode? updateTask() : createPlan()"
                     type="button">{{editMode? 'Update':'Submit'}}</b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueDropzone from '../VueDropzone.vue';
import Multiselect from 'vue-multiselect'

export default {
    components: { Multiselect, VueDropzone },
    props:{
        editItem:{
            type: Object,
            default: null
        },
        editMode:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            data: {
                name: "",
                slug: "",
                is_active: true,
                sort_order: 0,
                type: "",
                delivery_time: null,
                description:"",
                plans: []
            },
        }
    },
    watch: {
        'data.name': function (name){
            if(this.editMode) return
            this.data.slug = name.toString().normalize('NFD').replace(/[\u0300-\u036f]/g,'').replace(/\s+/g,'-').toLowerCase().replace(/&/g,'-and-').replace(/[^a-z0-9\-]/g,'').replace(/-+/g,'-').replace(/^-*/,'').replace(/-*$/,'');
        },
    },
    computed: {
        plans(){
            return this.$store.state.planList.data.map((i) => {
                return {
                    id: i.id, title: i.title,billing_period: i.billing_period
                }
            })
        },
    },
    methods:{
        customPlanLabel(item){
            return item.title +" " + item.billing_period + "ly";
        },
        createPlan() {
            this.$validator.validateAll().then(result => {
                if (!result) return;
                const formData = new FormData()
                if(!this.collectFiles().length){this.alertError("add task image"); return;}
                formData.append('image_file', this.collectFiles()[0])
                formData.append('data', JSON.stringify(this.data))
               
               this.$store.dispatch("changeLoaderValue", true)
                this.$http.post(`/tasks/create`, formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        this.$store.commit("taskList/ADD_TASK",response.data.data)
                        this.resetForm();
                    }
                })
            })
        },
        updateTask(){
            this.$validator.validateAll().then(result => {
                if (!result) return;
                const formData = new FormData()
                formData.append('data', JSON.stringify(this.data))
                if(this.collectFiles().length){
                    formData.append('image_file', this.collectFiles()[0])
                }
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post(`/tasks/${this.editItem.id}/update`,formData)
                    .then((response) => {
                        this.$store.dispatch("changeLoaderValue", false)
                        if(response.data.success){
                            this.$store.commit("taskList/UPDATE_TASK", response.data.data)
                            this.resetForm()
                        }
                    })
            
            })
        },
        collectFiles(){
            var arrafile = [];
            if(this.$refs.reqTaskUploadDrop){
                arrafile = [...this.$refs.reqTaskUploadDrop.getAcceptedFiles()];
            }
            return arrafile
        },
        resetForm(){
            if(this.$refs.reqTaskUploadDrop){
                this.$refs.reqTaskUploadDrop.removeAllFiles();
            }
            this.$validator.reset()
            this.$emit("closeMe")
        },
        patchUpdatables(){
            this.data = this.$options.filters
                        .patchUpdatables(this.data, this.editItem)
        }
    },
    mounted(){
        if(this.editMode){
            this.patchUpdatables()
        }
    }
}
</script>