
<template>
  <div class="task-list">
    <div class="row">
      <div class="col-12">
        <div class="card shadow-sm border">
          <div class="card-body">
            <div class="d-flex align-items-center justify-content-between">
              <h3 class="my-0">
                Tasks
              </h3>
              <b-button variant="primary" class=""
                @click.prevent="popupModalShow = true" type="button">
              <i class="mdi font-14 mdi-plus"></i> <span class="d-none d-sm-inline"> Add Task</span></b-button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mb-4">
        <h4 class="fw-normal">Search for task?</h4>
        <form class="task-search-form position-relative">
          <input v-model="searchQuery" class="task-search-input disable-x pr-4 form-control form-control-lg" type="search" placeholder="LogoType, business cards, social media graphics..." aria-label="LogoType, business cards, social media graphics...">
          <svg class="svgIcon-search position-absolute" height="24" width="24" xmlns="http://www.w3.org/2000/svg" style="top: 12px; right: 12px;"><g fill="none" fill-rule="evenodd" stroke="#A1ABB3" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" transform="translate(2 2)"><path d="M20 20l-6-6"></path> <circle cx="8" cy="8" r="8"></circle></g></svg>
        </form>
      </div>
    </div>
    <div v-if="!isLoading" class="row">
        <div v-for="(task, tIndex) in searchedTasks" 
          :key="tIndex" class="col-lg-6 mb-3">
            <div class="rounded bg-white d-flex align-items-center cursor-pointer shadow border border-1">
              <div class="p-2">
                <img style="height:50px;" class="" :alt="task.name" :src="absoluteUrl(task.image)">
              </div>
              <div class="flex-grow-1 text-truncate pr-4">
                <h5 class="mb-0">{{task.name}}</h5>
                <span class="text-dark">{{task.description}}</span>
              </div>
              <span>
                <b-dropdown variant="light" right size="sm">
                  <template slot="button-content">
                    <i class="mdi mdi-chevron-down"></i>
                  </template>
                  <b-dropdown-item-button @click="initEdit(task)"><i class="mdi mdi-square-edit-outline"></i> Edit</b-dropdown-item-button>
                  <b-dropdown-item :to="`/requirements?task_id=${task.id}`"><i class="mdi mdi-beaker-question-outline"></i> Requirements</b-dropdown-item>
                  <b-dropdown-item-button @click="deleteItem(task.id)"><i class="mdi mdi-trash-can-outline"></i> Delete</b-dropdown-item-button>
                </b-dropdown>
              </span>
            </div>
        </div>
    </div>
    <is-loading v-else />
    <b-modal v-model="popupModalShow" hide-footer centered
      :title="editMode? 'Update task':'Add task'"
      title-class="font-18" @hide="resetData()">
      <task-form v-if="popupModalShow" @closeMe="resetData()"
       :editMode="editMode" :editItem="editItem" />
    </b-modal>
  </div>
</template>

<script>

import TaskForm from "@/components/forms/TaskForm.vue"
import IsLoading from '@/components/IsLoading.vue'

export default {
  components:{
    TaskForm, IsLoading
  },
  data() {
    return {
      isLoading: true,
      popupModalShow: false,
      editMode: false,
      editItem: null,
      searchQuery:""
    }
  },
  computed: {
    taskList(){
      return this.$store.state.taskList.data
    },
    searchedTasks(){
      return this.taskList.filter(task => task.name ? task.name.toLowerCase().includes(this.searchQuery.toLowerCase()) : false)
    }
  },
  methods: {
    deleteItem(itemId){
      this.confirmDelete({
        text: "You are about to delete this task!",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/tasks/${itemId}/delete`)
          .then((response) => {
            this.$store.dispatch("changeLoaderValue", false)
            if(response.data.success){
              this.$store.commit("taskList/DELETE_TASK",itemId)
            }
          })
        }
      });
    },
    initEdit(task){
      this.editMode = true;
      this.editItem = {...task}
      this.popupModalShow = true  
    },
    resetData(){
      this.editMode = false;
      this.editItem = null;
      this.popupModalShow = false;
    },
    fetchPlans(){
      this.$store.dispatch("planList/fetchPlans")
    }
  },
  created(){
    this.fetchPlans();
    this.$store.dispatch("taskList/fetchTasks")
    .then((_) => { this.isLoading = false })
  }
}
</script>
